import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
import Notiflix from 'notiflix';

const DemoProduct = (props) => {
    useDocTitle('Code Crumble - Demo our products');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [demoProducts, setDemoProducts] = useState([]);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setDemoProducts([...demoProducts, value]);
        } else {
            setDemoProducts(demoProducts.filter((e) => e !== value));
        }
    };

    const clearErrors = () => {
        setErrors({});
    };

    const clearInput = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setDemoProducts([]); // Optionally clear selected products
    };

    const validateForm = () => {
        const newErrors = {};
        if (!firstName) newErrors.first_name = 'First Name is required';
        if (!lastName) newErrors.last_name = 'Last Name is required';
        if (!email) newErrors.email = 'Email is required';
        if (!phone) newErrors.phone_number = 'Phone number is required';
        if (!message) newErrors.message = 'Message is required';
        if (demoProducts.length === 0) newErrors.products = 'At least one product must be selected';

        return newErrors;
    };

    const simulateEmailSubmission = async () => {
        // Simulate a delay to mimic an API call
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ message: 'Thank you for your submission!' });
            }, 1000);
        });
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Stop the form submission if there are validation errors
        }

        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        
        try {
            // Simulate the API call
            const response = await simulateEmailSubmission();
            // If the submission is successful
            Notiflix.Report.success('Success', response.message, 'Okay');
            toast.success(response.message); // Show toast message
            clearInput();
        } catch (error) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'Send Message';
            Notiflix.Report.failure('An error occurred', 'There was an issue with your submission.', 'Okay');
        } finally {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'Send Message';
        }
   
        // const sendEmail = async (e) => {
        // e.preventDefault();
        // const validationErrors = validateForm();
        // if (Object.keys(validationErrors).length > 0) {
        //     setErrors(validationErrors);
        //     return; // Stop the form submission if there are validation errors
        // }

        // document.getElementById('submitBtn').disabled = true;
        // document.getElementById('submitBtn').innerHTML = 'Loading...';
        
        // const fData = new FormData();
        // fData.append('first_name', firstName);
        // fData.append('last_name', lastName);
        // fData.append('email', email);
        // fData.append('phone_number', phone);
        // fData.append('message', message);
        // fData.append('products', demoProducts);

        // axios({
        //     method: "post",
        //     url: process.env.REACT_APP_DEMO_REQUEST_API,
        //     data: fData,
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },
        // })
        // .then(function (response) {
        //     document.getElementById('submitBtn').disabled = false;
        //     document.getElementById('submitBtn').innerHTML = 'Send Message';
        //     clearInput();
        //     Notiflix.Report.success('Success', response.data.message, 'Okay');
        // })
        // .catch(function (error) {
        //     document.getElementById('submitBtn').disabled = false;
        //     document.getElementById('submitBtn').innerHTML = 'Send Message';
        //     const { response } = error;
        //     if (response.status === 500) {
        //         Notiflix.Report.failure('An error occurred', response.data.message, 'Okay');
        //     }
        //     if (response.data.errors) {
        //         setErrors(response.data.errors);
        //     }
        // });
    };

    
    return (
        <>
          <ToastContainer />
            <div>
                <NavBar />
            </div>
            <div id='demo' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                <form onSubmit={sendEmail} id="demoProductForm">
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Demo our products</h1>
                            </div>
                            <div className="flex items-center my-4">
                                <input 
                                    id="checkbox-1" 
                                    type="checkbox" 
                                    className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" 
                                    value="business_management_system" onChange={handleChange}
                                />
                                <label htmlFor="checkbox-1" className="ml-3 text-lg font-medium text-gray-900">Business Management System</label>
                            </div>
                            <div className="flex items-center my-4">
                                <input 
                                    id="checkbox-2" 
                                    type="checkbox" 
                                    className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                                    value="school_management_portal" onChange={handleChange}
                                />
                                <label htmlFor="checkbox-2" className="ml-3 text-lg font-medium text-gray-900">School Management Portal</label>
                            </div>
                            <div className="flex items-center my-4">
                                <input 
                                    id="checkbox-3" 
                                    type="checkbox" 
                                    className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" 
                                    value="payroll_management_system" onChange={handleChange}
                                />
                                <label htmlFor="checkbox-3" className="ml-3 text-lg font-medium text-gray-900">Payroll Management System</label>
                            </div>
                            <div className="flex items-center my-4">
                                <input 
                                    id="checkbox-4" 
                                    type="checkbox" 
                                    className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                                    value="event_management_system" onChange={handleChange}
                                />
                                <label htmlFor="checkbox-4" className="ml-3 text-lg font-medium text-gray-900">Event Management System</label>
                            </div>
                            {errors.products && 
                                <p className="text-red-500 text-sm">{errors.products}</p>
                            }
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input 
                                        name="first_name" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="First Name*" 
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.first_name && 
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>
                                <div>
                                    <input 
                                        name="last_name" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.last_name && 
                                        <p className="text-red-500 text-sm">{errors.last_name}</p>
                                    }
                                </div>
                                <div>
                                    <input 
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email" 
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}   
                                    />
                                    {errors.email && 
                                        <p className="text-red-500 text-sm">{errors.email}</p>
                                    }
                                </div>
                                <div>
                                    <input
                                        name="phone_number" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number" 
                                        placeholder="Phone*"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.phone_number && 
                                        <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                    }
                                </div>
                            </div>
                            <div className="my-4">
                                <textarea 
                                    name="message" 
                                    placeholder="Message*" 
                                    className="w-full h-40 p-3 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                ></textarea>
                                {errors.message && 
                                    <p className="text-red-500 text-sm">{errors.message}</p>
                                }
                            </div>
                            <div className="my-4">
                                <button
                                    id="submitBtn"
                                    type="submit"
                                    className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
                        <div className="flex flex-col text-white">     
                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                       <h2 className="text-2xl">Office Address</h2>
                                       <p className="text-gray-400 mb-1">83, Easton Street,</p>
                                       <p className="text-gray-400 mb-1">High Wycombe, HP111LT,</p>
                                       <p className="text-gray-400 mb-1">Buckinghamshire,</p>
                                       <p className="text-gray-400">United Kingdom</p>
                                    </div>
                            </div>
                            
                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                      <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                  <h2 className="text-2xl">Call Us</h2>
                                <div className="flex space-x-2">
                                  <p className="text-gray-400">Tel:</p>
                                  <p className="text-gray-400">+447458671438</p>
                                </div>
                                <div className='mt-5'>
                                  <h2 className="text-2xl whitespace-nowrap">Send an E-mail</h2>
                                  <p className="text-gray-400">info@codecrumble.in</p>
                                </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DemoProduct;
